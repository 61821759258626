<template>
  <BaseToolbar>
    <div class="flex h-100 ai-c ggap-10">
      <FormInput class="w-200" placeholder="Поиск" v-model="filters.name" />
      <FormAutocomplete
        class="w-200n"
        isMultiple
        placeholder="Проект"
        showFirstOnly
        :options="optionsProjects"
        v-model="filters.projects"
      />
    </div>
  </BaseToolbar>
  <section class="pos-r p-30 templates-list">
    <BaseLoad v-if="isLoad" class="bg" />
    <div class="flex fd-c ggap-10 ai-fs jc-fs scroll h-100" v-if="templates?.length">
      <TemplateItem v-for="item in templates" :key="item?.id" :item="item" />
    </div>
    <BaseAlert v-else type="yellow">Ничего не найдено!</BaseAlert>
  </section>
  <RouterView />
</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue'
import { BaseToolbar, BaseLoad, FormInput, FormAutocomplete, BaseAlert } from '@/components'
import { useStore } from 'vuex'
import { debounce } from 'lodash'
import TemplateItem from './components/TemplateItem'

// Computed
const optionsProjects = computed(() => store.getters.projects || [])
const templates = computed(() => store.getters['templates/rows'])
const filtersStore = computed(() => store.getters['templates/filters'] || [])
const page = computed(() => store.getters['templates/page'])
const pageCount = computed(() => store.getters['templates/pageCount'])

// Data
const isLoad = ref(false)
const isLoadMore = ref(false)
const store = useStore()
const filters = ref(filtersStore.value)

onMounted(() => {
  const el = document.querySelector('.templates-list')
  el.addEventListener('scroll', async function () {
    if (el.scrollTop > el.scrollHeight - 1000) await getNextRows()
  })
})

// Watch
watch(
  filters.value,
  debounce(() => {
    getRows()
  }, 1000)
)

// Created
store.dispatch('GET_PROJECTS')
getRows()

// Methods
async function getRows() {
  isLoad.value = true
  await store.commit('templates/SET_FILTERS', filters.value)
  await store.dispatch('templates/GET_ROWS')
  isLoad.value = false
}

function getNextRows() {
  if (page.value < pageCount.value && !isLoadMore.value) {
    isLoadMore.value = true
    store.dispatch('templates/GET_MORE_ROWS').finally(() => {
      isLoadMore.value = false
    })
  }
}
</script>

<style scoped>
.templates-list {
  height: calc(100vh - 100px);
  overflow-y: auto;
  box-sizing: border-box;
}
</style>
