<template>
  <!-- Main -->
  <div class="box p-10 flex ggap-5 ai-c jc-sb hover-item w-100" v-if="!isDelete && !isDeleteConfirm">
    <p class="w-30vw cut m-0">{{ item?.template_name }}</p>
    <span>{{ moment(item?.created_at).format('DD.MM.YYYY') || '18.08.2023' }}</span>
    <span class="w-300">{{ item?.project?.project }}</span>
    <span class="flex ai-c ggap-5">
      <BaseIcon class="ic-16 white" icon="user" />
      <span>{{ item?.author?.name }} {{ item?.author?.surname }}</span>
    </span>
    <div class="flex ggap-5">
      <a :href="item?.path" class="btn green sm p-10" :download="item?.name" v-tippy="'Скачать шаблон'">
        <BaseIcon class="ic-16 white" icon="download" />
      </a>
      <button
        class="btn primary sm p-10"
        @click="$router.push(`/templates/edit/${item?.id}`)"
        v-tippy="'Редактировать'"
        v-if="isLawyer || isSuperLawyer"
      >
        <BaseIcon class="ic-16 white" icon="edit" />
      </button>
      <button
        class="btn secondary sm p-10"
        @click="isDelete = true"
        v-if="isLawyer || isSuperLawyer"
        v-tippy="'Удалить'"
      >
        <BaseIcon class="ic-16 white" icon="delete" />
      </button>
    </div>
  </div>
  <!-- Main -->

  <!-- Confirm delete -->
  <div class="box p-10 flex ggap-5 ai-c w-100 jc-sb deleted-item pos-r" v-if="isDelete && !isDeleteConfirm">
    <div class="flex fd-c ggap-5 ai-c ta-center w-100">
      <p class="fw-b ta-center m-0">Вы уверены что хотите удалить?</p>
    </div>
    <div class="flex ggap-5">
      <button class="btn primary sm p-10" @click="deleteDocument">Да</button>
      <button class="btn secondary sm p-10" @click="isDelete = false">Нет</button>
    </div>
  </div>
  <!-- Confirm delete -->

  <!-- Delete -->
  <div class="box p-10 flex w-100 ggap-20 ai-c jc-c deleted-item pos-r" v-if="!isDelete && isDeleteConfirm">
    <BaseLoad v-if="isLoad" class="rel sm z9999 white-transparent" />
    <p class="fw-b fs-16 m-0">Идет удаление записи</p>
  </div>
  <!-- Delete -->
</template>

<script setup>
import { defineProps, toRefs, computed, ref } from 'vue'
import { BaseIcon, BaseLoad } from '@/components'
import { useStore } from 'vuex'
import moment from 'moment'
import templates from '@/api/modules/templates'

// Props
const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
})

// Data
const { item } = toRefs(props)
const store = useStore()
const isDelete = ref(false)
const isDeleteConfirm = ref(false)
const isLoad = ref(true)

// Computed
const profile = computed(() => store.getters.profile)
const isSuperLawyer = computed(() => profile.value?.lawyer_role === 'Senior Lawyer')
const isLawyer = computed(() => profile.value?.lawyer_role === 'Lawyer')

// Methods
async function deleteDocument() {
  try {
    isDelete.value = false
    isDeleteConfirm.value = true
    await templates.delete(item.value?.id)
    await store.dispatch('templates/GET_ROWS')
  } catch (err) {
    console.log(err)
  } finally {
    isDeleteConfirm.value = false
  }
}
</script>

<style lang="scss" template-item>
.hover-item:hover {
  background: var(--grey-el);
}
</style>
